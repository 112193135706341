import _ from "lodash";
import axios from "axios";
axios.defaults.baseURL = API_BASE_URL + 'biometric/';
axios.defaults.headers.common['Content-Type'] = 'application/json';

/**
 * @Description
 * 1. set throtlling to 10000
 * 2. check if there is not access token the throw the error of missing access token
 * 3. check if iframe is already created and url is set then return
 * 4. if iframe is not created then create the iframe
 * 5. start the status request, using getRequestStatus method
 *
 * @param {callback}  callback function
 * @param {accessToken} String (access token generated in Server side request)
 * @return void
 **/
export const setThrottle = function() {
    this._throttle = _.throttle(async(callback, accessToken) => {

        if (!accessToken) {
            throw new Error('Access Token is missing. Please provide access token to init Shufti Pro SDK');
            return;
        }

        let verificationUrl = BASE_URL + "biometric/verification/" + accessToken;
        checkAndCreateIframe(verificationUrl);
        await getRequestStatus(callback, accessToken);

    }, 10000)
};

/**
 * @Descriotion
 * 1. used to check verification status continuously through out the request
 * 2. main purpose of doing so is to get the verification status as soon as completed
 * 3. async method and send axios GET request to api to get request status
 * 4. if status is received then call the callback {response back to client}
 * 5. check if cancelled status received then instantly return the response, otherwise after 5 seconds
 * 6. otherwise recursively call the getRequestStatus [to handle the long polling]
 *
 * @param {callback} callback function
 * @param {accessToken} String {access token generated in Server side request}
 * @return void
 **/
const getRequestStatus = async(callback, accessToken) => {
    try {
        let data = (await axios.get(BASE_URL + 'api/v2/verification/status/' + accessToken)).data
        if (data.data !== null) {
            let oIframe = document.getElementById('shuftipro-iframe');
            if (data.data.status && data.data.status.toLowerCase().indexOf('cancelled') > -1) {
                if (oIframe && oIframe.dataset.removable) {
                    document.getElementById('shuftipro-iframe').remove();
                }
                setTimeout(() => callback(data.data))
            } else {
                if (oIframe && oIframe.dataset.removable) {
                    setTimeout(() => document.getElementById('shuftipro-iframe').remove(), 5000)
                }
                setTimeout(() => callback(data.data), 5000)
            }
        } else {
            await timeout(5000);
            return getRequestStatus(callback, accessToken);
        }
    } catch (e) {
        await getRequestStatus(callback, accessToken)
    }
};

/**
 * @Description
 * 1. This method is used to handle iframe check and create operations
 * 2. check if iframe is created already and source is already set then return
 * 3. if iframe is already created and source is not set then set the src to iframe and return
 * 4. otherwise set the iframe attributes and created new DOM element(iframe)
 *
 * @param {src} url string
 * @return void
 **/
const checkAndCreateIframe = src => {

    let oIframe = document.getElementById('shuftipro-iframe');
    if (oIframe && oIframe.src.includes(BASE_URL + 'biometric/verification/')) return;

    if (oIframe) {
        oIframe.src = src;
        oIframe.name = 'shuftipro-iframe';
        return;
    }

    let iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.id = 'shuftipro-iframe';
    iframe.name = 'shuftipro-iframe';
    iframe.allow = "camera";
    iframe.src = src;
    iframe.style.top = 0;
    iframe.style.left = 0;
    iframe.style.bottom = 0;
    iframe.style.right = 0;
    iframe.style.margin = 0;
    iframe.style.padding = 0;
    iframe.style.overflow = 'hidden';
    iframe.style.border = "none";
    iframe.style.zIndex = "2147483647";
    iframe.width = "100%";
    iframe.height = "100%";
    iframe.dataset.removable = true;

    document.body.appendChild(iframe);
};

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
